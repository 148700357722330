/* eslint-disable no-secrets/no-secrets */
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface SEOValues {
	description?: string;
	lang?: string;
	title?: string;
	meta?: Array<{ [key: string]: string }>;
	keywords?: string;
}

function SEO({
	description,
	lang,
	title,
	meta,
	keywords,
}: SEOValues): JSX.Element {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const metaDescription =
		description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${defaultTitle}`}
			defaultTitle={defaultTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: `keywords`,
					content:
						keywords || "Encore, Pittsburgh, Apartments",
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				...(meta ?? []),
			]}
		>
			<meta
				name="google-site-verification"
				content="s477Z1FCSLyl4GZFcQs_4wofxbqBR9dc8l157pUqmoE"
			/>
			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=G-P6GBTMMSP1"
			/>
			<script>
				{`window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', 'G-P6GBTMMSP1');`}
			</script>
		</Helmet>
	);
}

export default SEO;
