/* eslint-disable prettier/prettier */
import React from "react";
import { graphql, Link } from "gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import tw, { css } from "twin.macro";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {
	AppStyles,
	Body,
	H2,
	TextLink,
	ButtonBlue,
} from "../styles/AppStyles.styles.tw";

type AvailableProps = {
	applicationURL: string;
	dateAvailable: string;
	floorplanId: string;
	floorplanName: string;
	id: string;
	minRent: number;
	name: string;
	sqFt: number;
};

type SanityFloorplan = {
	header: string;
	heroImage: {
		alt: string;
		asset: {
			gatsbyImageData: IGatsbyImageData;
			url: string;
		};
	};
	keywords: string[];
	pageDescription: string;
	pageTitle: string;
	linkText: string;
	linkURL: string;
	body: string;
};

type PageData = {
	pageContext: {
		id: string;
		name: string;
	};
	data: {
		sanityFloorplans: SanityFloorplan;
		apts: {
			nodes: AvailableProps[];
		};
	};
};

const AvailableHeader = () => {
	return (
		<>
			<div tw="hidden lg:margin[-.5px] lg:block bg-navy uppercase font-bold text-sm lg:text-lg text-white tracking-widest w-full h-full">
				Apartment
			</div>
			<div tw="hidden lg:margin[-.5px] lg:block bg-navy uppercase font-bold text-sm lg:text-lg text-white tracking-widest w-full h-full">
				Sq. Ft.
			</div>
			<div tw="hidden lg:margin[-.5px] lg:block bg-navy uppercase font-bold text-sm lg:text-lg text-white tracking-widest w-full h-full">
				Rent
			</div>
			<div tw="hidden lg:margin[-.5px] lg:block bg-navy uppercase font-bold text-sm lg:text-lg text-white tracking-widest w-full h-full">
				Action
			</div>
		</>
	);
};

const AvailableRow = (apt: AvailableProps) => {
	const {
		name,
		sqFt,
		minRent,
		dateAvailable,
		applicationURL,
	} = apt;

	return (
		<>
			<div tw="flex mt-4 margin-bottom[-.5px] lg:margin[-.5px] justify-between items-center lg:justify-center bg-beige text-brown w-full h-16 px-4 border border-brown">
				<span tw="lg:hidden">Apartment</span>
				<span tw="">{name}</span>
			</div>
			<div tw="flex margin[-.5px 0] lg:margin[-.5px]  justify-between items-center lg:justify-center bg-beige text-brown w-full h-16 px-4 border border-brown">
				<span tw="lg:hidden">SQ. FT.</span>
				<span tw="">{sqFt.toLocaleString()}</span>
			</div>
			<div tw="flex margin[-.5px 0] lg:margin[-.5px]  justify-between items-center lg:justify-center bg-beige text-brown w-full h-16 px-4 border border-brown">
				<span tw="lg:hidden">Rent</span>
				{minRent !== -1 ? (
					<span tw="">
						{
							minRent
								.toLocaleString(`en-US`, {
									style: `currency`,
									currency: `USD`,
								})
								.split(`.`)[0]
						}
					</span>
				) : (
					<span tw="">Call for pricing</span>
				)}
			</div>
			<div tw="flex margin[-.5px 0] justify-between items-center lg:justify-center bg-beige text-brown w-full h-16 px-4 border border-brown">
				<span tw="lg:hidden">Action</span>
				<a
					href={applicationURL}
					target="_blank"
					rel="noreferrer"
				>
					<ButtonBlue tw="w-32">Select</ButtonBlue>
				</a>
			</div>
		</>
	);
};

export default function AvailabilityTemplate({
	pageContext,
	data,
}: PageData) {
	const floorPlanBed = css`
		background-image: url(${data.sanityFloorplans.heroImage
			.asset.url});
		@media screen and (min-width: 768px) {
			min-height: 650px;
		}
	`;

	const available = data.apts.nodes;

	return (
		<Layout title="floor-plans">
			<SEO
				title={data.sanityFloorplans.pageTitle}
				description={data.sanityFloorplans.pageDescription}
				keywords={data.sanityFloorplans.keywords.join(", ")}
			/>
			<AppStyles>
				<section
					css={[
						tw`flex flex-col items-center justify-center px-4 py-32 text-center text-white bg-center bg-no-repeat bg-cover lg:px-0 2xl:py-48`,
						floorPlanBed,
					]}
				>
					<H2 tw="mb-4">{data.sanityFloorplans.header}</H2>
					<Body tw="max-w-xl">
						{data.sanityFloorplans.body}{" "}
						{data.sanityFloorplans.linkURL && (
							<TextLink
								tw="text-white"
								href={data.sanityFloorplans.linkURL}
							>
								{data.sanityFloorplans.linkText}
							</TextLink>
						)}
					</Body>
				</section>
				<section tw="p-8 lg:p-16 w-full">
					<div tw="container mx-auto flex flex-col justify-center items-center text-center space-y-8">
						<div tw="lg:space-y-4 w-full">
							<h2 tw="font-bold font-title text-4xl text-brown">
								{pageContext.name}
							</h2>
							{available.length > 0 ? (
								<>
									<div tw="grid grid-cols-1 lg:grid-cols-4 place-items-center text-center w-full h-full mx-auto tracking-wider">
										<AvailableHeader />
										{available.map((apt) => (
											<AvailableRow key={apt.id} {...apt} />
										))}
									</div>
									<div tw="mt-4">
										<Link to="/floor-plans">
											<button
												tw="text-brown text-sm uppercase focus:outline-none"
												type="button"
											>
												{`< Back to Floor Plans`}
											</button>
										</Link>
									</div>
								</>
							) : (
								<div tw="lg:text-lg space-y-4">
									<span>
										There are no available apartments
									</span>

									<div>
										<Link to="/floor-plans">
											<ButtonBlue>Go Back</ButtonBlue>
										</Link>
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
			</AppStyles>
		</Layout>
	);
}

export const query = graphql`
	query($name: String!) {
		sanityFloorplans {
			header
			heroImage {
				alt
				asset {
					gatsbyImageData
					url
				}
			}
			keywords
			pageDescription
			pageTitle
			linkText
			linkURL
			body
		}
		# floorplan(: { eq: $id }) {
		# 	name
		# }
		apts: allAvailability(
			filter: { floorplanName: { eq: $name } }
		) {
			nodes {
				applicationURL
				dateAvailable
				floorplanId
				id
				minRent
				name
				sqFt
			}
		}
	}
`;
